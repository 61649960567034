import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useCurrentRefinements, useHits, useSearchBox } from "react-instantsearch";
import PropTypes from "prop-types";
import TreeList, { Column, Scrolling } from "devextreme-react/tree-list";
import ViewFileButtons from "./view-file-buttons";
import "../../pages/document-search/document-search.scss";

function CustomHits({
  algoliaIndexName,
  searchClient,
  isCollapseAll,
  setIsCollapseAll,
  isFilterOpen,
  ...props
}) {
  console.log("rendering CustomHits...");
  const treeListRef = useRef(null);
  const { query } = useSearchBox();
  const { items } = useCurrentRefinements(props);

  const { results } = useHits(props);
  const [allTransformedHits, setAllTransformedHits] = useState([]);
  const [loading, setLoading] = useState(true);

  // Resize the TreeList when the filter panel is opened/closed
  useEffect(() => {
    if (!treeListRef.current) return;

    const timer = setTimeout(() => {
      treeListRef.current.instance().updateDimensions(); // Ensure smooth resize
    }, 300); // Matches the transition duration
    return () => clearTimeout(timer);
  }, [isFilterOpen]);

  // Expand all nodes when there is a query or filter items
  useEffect(() => {
    if (isCollapseAll && (query !== "" || items.length > 0)) {
      setIsCollapseAll(false);
    } else if (!isCollapseAll && query === "" && items.length === 0) {
      setIsCollapseAll(true);
    }
  }, [query, items]);

  // Refresh the TreeList when the collapse state changes
  useEffect(() => {
    if (!treeListRef.current) return;
    treeListRef.current.instance().state(null);
  }, [isCollapseAll]);

  // Transform the data when the results change
  useEffect(() => {
    setLoading(true);

    if (query !== "" || (results && results.nbHits <= 1000)) {
      // if there is a query OR the number of hits <= 1000, then we can use the results directly
      console.log("nbHits: ", results.nbHits, "...using results directly");

      if (!results) return null;
      const { hits } = results;

      const transformedData = transformData(hits);
      setAllTransformedHits(transformedData);
    } else {
      // fetch all records
      console.log("nbHits: ", results.nbHits, "...fetching all records");

      const fetchAllRecords = async () => {
        let allHits = [];
        let currentPage = 0;

        const totalPages = results ? results.nbPages : 0;
        const filters =
          items.length > 0
            ? items
                .flatMap((item) => {
                  if (item.refinements.length > 1) {
                    // If there are multiple refinements for the same attribute, use OR
                    return `(${item.refinements
                      .map((refinement) => `${refinement.attribute}:"${refinement.value}"`)
                      .join(" OR ")})`;
                  } else {
                    // Otherwise, use AND for each individual refinement
                    return `${item.refinements
                      .map((refinement) => `${refinement.attribute}:"${refinement.value}"`)
                      .join(" AND ")}`;
                  }
                })
                .join(" AND ") // Combine different attributes with AND
            : undefined;

        while (currentPage < totalPages) {
          const result = await searchClient
            .initIndex(algoliaIndexName)
            .search("", { filters: filters, page: currentPage });

          allHits = [...allHits, ...result.hits];
          currentPage++;
        }

        const transformedData = transformData(allHits);
        setAllTransformedHits(transformedData);
        treeListRef.current.instance().refresh();
      };

      if (results) {
        fetchAllRecords();
      }
    }
  }, [results]);

  const transformData = (hits) => {
    // console.log("transforming data... number of hits: ", hits.length);

    // Transform the data into a nested structure with parent-child relationships and unified field
    return hits.reduce((acc, hit) => {
      const { objectID, category_path, fiscal_year, division, report_name, ...otherFields } = hit;

      // Extract levels and ensure category_path has valid levels
      const lvl0 = category_path.lvl0 && category_path.lvl0 !== "Unknown Level 0" ? category_path.lvl0 : null;
      const lvl1 =
        category_path.lvl1 && category_path.lvl1 !== "Unknown Level 1"
          ? category_path.lvl1.split(" > ").pop()
          : null;
      const lvl2 =
        category_path.lvl2 && category_path.lvl2 !== "Unknown Level 2"
          ? category_path.lvl2.split(" > ").pop()
          : null;

      // Find or create lvl0 (top-most level)
      let lvl0Node = lvl0 ? acc.find((node) => node.level_name === lvl0) : null;
      if (lvl0 && !lvl0Node) {
        lvl0Node = { id: `lvl0_${lvl0}`, level_name: lvl0, children: [] };
        acc.push(lvl0Node);
      }

      // Find or create lvl1 under this lvl0
      let lvl1Node = lvl1 ? lvl0Node?.children.find((node) => node.level_name === lvl1) : null;
      if (lvl1 && lvl0Node && !lvl1Node) {
        lvl1Node = { id: `lvl1_${lvl1}`, level_name: lvl1, children: [] };
        lvl0Node.children.push(lvl1Node);
      }

      // Find or create lvl2 under this lvl1
      let lvl2Node = lvl2 ? lvl1Node?.children.find((node) => node.level_name === lvl2) : null;
      if (lvl2 && lvl1Node && !lvl2Node) {
        lvl2Node = { id: `lvl2_${lvl2}`, level_name: lvl2, children: [] };
        lvl1Node.children.push(lvl2Node);
      }

      // Reassign reports to the nearest known level (lvl2 > lvl1 > lvl0)
      const parent = lvl2Node || lvl1Node || lvl0Node;
      if (parent) {
        parent.children.push({
          id: objectID,
          level_name: report_name, // Use the report name at the document level
          fiscal_year,
          division,
          ...otherFields,
        });
      }
      return acc;
    }, []);
  };

  return (
    <>
      {loading && (
        <div className="d-flex flex-column justify-content-center align-items-center gap-3 py-5">
          <CircularProgress /> <h5>Loading reports...</h5>
        </div>
      )}
      <TreeList
        id="reports-treelist"
        ref={treeListRef}
        dataSource={allTransformedHits}
        dataStructure="tree"
        itemsExpr="children"
        showRowLines={true}
        showBorders={true}
        columnAutoWidth={true}
        autoExpandAll={!isCollapseAll}
        visible={!loading}
        onRowPrepared={(e) => {
          if (e.rowType === "data") {
            // Check if the row has children or not
            const isLeafNode = e.node.children.length > 0;

            // Apply background color if it's the last child
            if (isLeafNode) {
              e.rowElement.style.backgroundColor = "#efefef";
            }
          }
        }}
        onContentReady={(e) => {
          setLoading(false);
        }}
      >
        <Scrolling mode="virtual" />

        <Column
          dataField="level_name"
          caption="Report"
          cellRender={(cellData) => {
            const hasChildren = cellData.row.node.children.length > 0;
            return (
              <div
                style={{
                  fontWeight: hasChildren ? "bold" : "normal",
                }}
              >
                {cellData.value}
              </div>
            );
          }}
        />
        <Column
          caption="View Report"
          cellRender={(cellData) => <ViewFileButtons hit={cellData.data} />}
          alignment="center"
        />
        <Column dataField="fiscal_year" caption="Fiscal Year" alignment="center" />
        <Column dataField="quarter" caption="Quarter" alignment="center" />
        <Column dataField="month" caption="Month" alignment="center" />
        <Column dataField="division" caption="Division" alignment="center" />
      </TreeList>
    </>
  );
}

CustomHits.propTypes = {
  algoliaIndexName: PropTypes.string,
  searchClient: PropTypes.object,
  isCollapseAll: PropTypes.bool,
  setIsCollapseAll: PropTypes.func,
  isFilterOpen: PropTypes.bool,
};

export default CustomHits;
