import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import _ from "lodash";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import Loader from "../loader";
import PropType from "prop-types";

const bannerTag = "aboutBanner";

function DivisionAboutBanner({ divisionAbbreviation, divisionTag, linkTo }) {
  const [division, setDivision] = useState([]);
  const [aboutImage, setAboutImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData().then(() => setIsLoading(false));
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi(`Division/${divisionAbbreviation}`);
    setDivision(result);

    const querystring = `tags=${divisionTag}&tags=${bannerTag}`;
    const image = await getDataFromCmsApi(`asset/tags?${querystring}`);
    let filteredImage = _.filter(image, (item) => {
      return item.tags.includes(divisionTag) && item.tags.includes(bannerTag);
    });
    setAboutImage(_.head(filteredImage)?.fileUrl);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div
      className="d-flex flex-column justify-content-center align-items-center text-center text-white py-5 my-5"
      style={{
        backgroundImage: `url(${aboutImage})`,
        backgroundSize: "cover",
        backgroundColor: "#616161",
        backgroundBlendMode: "multiply",
      }}
    >
      <h1 className="display-4 m-4">
        About {divisionAbbreviation === "ITD" ? division.divisionAbbreviation : division.divisionName}
      </h1>
      <p className="container m-4">
        {division?.divisionShortDescription &&
          parseHtml(division.divisionShortDescription.replace(/\n/g, "<br />"))}
      </p>
      <Button
        className="m-4"
        variant="outlined"
        size="large"
        component={Link}
        to={linkTo}
        sx={{
          // fontSize: "1.1rem",
          color: "white",
          borderColor: "white",
          transition: "transform 0.15s ease-in-out",
          "&:hover": {
            borderColor: "white",
            backgroundColor: "rgba(255, 255, 255, 0.15)",
            // transform: "scale(1.05)",
          },
        }}
      >
        View More About{" "}
        {divisionAbbreviation === "ITD" ? division.divisionAbbreviation : division.divisionName}
      </Button>
    </div>
  );
}

DivisionAboutBanner.propTypes = {
  divisionAbbreviation: PropType.string.isRequired,
  divisionTag: PropType.string.isRequired,
  linkTo: PropType.string.isRequired,
};

export default DivisionAboutBanner;
