import React, { useEffect, useRef, useState } from 'react';
import { getIsAuthenticated, getSecondaryToken, signIn } from '../../services/auth';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Loader from '../loader.js';
import { loadGSGScript } from '../../services/load-gsg-scripts.js';
import { useSelector } from 'react-redux';

GSGDirectChargesWidget.propTypes = {
    triggerRerender: PropTypes.bool
};

function GSGDirectChargesWidget({ triggerRerender }) {
    const [language, setLanguage] = useState("en");
    const [isDropdownRendered, setIsDropdownRendered] = useState(false);
    const [loading, setLoading] = useState(true); // Loader state
    const [isAuthenticated, setIsAuthenticated] = useState(false);//useSelector((state) => state?.user ?? null);
    const languageDropdownRef = useRef(null);
    const getReduxAuth = useSelector((state) => state);

    useEffect(() => {
        const checkAuthStatus = async () => {
            const authStatus = await getIsAuthenticated();
            setIsAuthenticated(authStatus);
            setLoading(false);
            if (authStatus) {
                initializeGSG(); // Initialize only after authentication is confirmed
            }
        };
        checkAuthStatus();
        // initializeGSG(); console.log("Initializing in checkAuthStatus")
    }, []);

    useEffect(() => {
        const languageDropdown = document.getElementsByClassName('gt_selector')[0];
        languageDropdownRef.current = languageDropdown;

        if (languageDropdown) {
            setIsDropdownRendered(true);
            languageDropdown.addEventListener('change', handleLanguageDropdownChange);
            handleLanguageDropdownChange(); // Ensure initial load checks language
        }
        return () => {
            languageDropdown?.removeEventListener('change', handleLanguageDropdownChange);
        };
    }, [loading]);

    const handleLanguageDropdownChange = () => {
        const selectedValue = document.getElementsByClassName("gt_selector")[0]?.value.substring(5);
        setLanguage(selectedValue === "es" ? "es" : "en");
    };

    const handleLogin = () => {
        signIn();
    };

    const initializeGSG = async () => {
        try {
            await loadGSGScript(process.env.REACT_APP_GSG_DIRECT_CHARGES_SCRIPT, "DirectCharge"); // Load the script and wait for GSGWidget to initialize
            attachGSGDirectCharge();
        } catch (error) {
            console.error("Error initializing GSGWidget:", error);
        }
    };

    const attachGSGDirectCharge = () => {
        if (!isAuthenticated) return;
        window.DirectCharge.attach({
            site: 'ca-sanbernardino/direct-charge',
            elementSelector: '#gsg-direct-charge',
            environment: process.env.REACT_APP_GSG_ENVIRONMENT,
            language: language,
            // Secondary token will only work in a deployed environment, not locally.
            getJwt: () => getSecondaryToken().then(token => token),
            enableTestMode: false,
            initialPath: '/',
            onError: function (error) {
                console.error(error.message);
            },
        });
    };

    useEffect(() => {
        if (isAuthenticated && (isDropdownRendered || !loading)) {
            initializeGSG();
        }
    }, [isAuthenticated, triggerRerender, language, isDropdownRendered, loading, language]);


    if (loading) {
        // Render a loader while authentication is being checked
        return <Loader />;
    }

    return (
        <div className="gsg-container">
            <div className="container mt-5">
                <div className="row">
                    {isAuthenticated ? (
                        <div id="gsg-direct-charge"></div>
                    ) : (
                        <div>
                            <p className='lead'>Please log in to access Direct Charges.</p>
                            <Button onClick={handleLogin} variant="contained" color="primary">Log In</Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GSGDirectChargesWidget;
