import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, List, ListItemButton, Typography, Grid } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { isExternalLink } from "../../utils/utils";
import { getDataFromCmsApi } from "../../api/contentful-service";

const DivisionMoreServices = ({ divisionAbbreviation }) => {
  const [servicesList, setServicesList] = useState();

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    let result = await getDataFromCmsApi(`DivisionService/${divisionAbbreviation}`);

    const moreServives = result
      .filter((service) => service.isMoreServices && service.moreServicesCategory)
      .sort(
        (a, b) => a.moreServicesCategory.localeCompare(b.moreServicesCategory) || a.sortOrder - b.sortOrder
      )
      .reduce((acc, item) => {
        (acc[item.moreServicesCategory] = acc[item.moreServicesCategory] || []).push(item);
        return acc;
      }, {});
    setServicesList(moreServives);
  };

  return (
    <Box className="pt-4 pb-5">
      <Typography variant="h5" className="pb-3 px-3 px-md-0 text-center text-md-start">
        More Services
      </Typography>

      <Grid container columnSpacing={12} rowSpacing={6} className="px-3 px-lg-0">
        {servicesList &&
          Object.entries(servicesList).map(([category, items], index) => (
            <Grid key={index} item sm={12} md={6} lg={4} className="w-100">
              <List>
                <div
                  className="d-flex column-gap-2 justify-content-center align-items-center pb-1 mb-2"
                  style={{ borderBottom: `3px solid #003366`, color: "#003366" }}
                >
                  <span className="fw-bold fs-5">{category}</span>
                </div>
                {items.map((link, i) => (
                  <ListItemButton
                    key={i}
                    className="d-flex justify-content-between"
                    divider
                    component={Link}
                    to={link.link}
                    target={isExternalLink(link.link) ? "_blank" : ""}
                    rel={isExternalLink(link.link) ? "noopener noreferrer" : ""}
                  >
                    {link.serviceLabel}
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                  </ListItemButton>
                ))}
              </List>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

DivisionMoreServices.propTypes = {
  divisionAbbreviation: PropTypes.string.isRequired,
};

export default DivisionMoreServices;
