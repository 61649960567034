import { getDataFromCmsApi } from "../api/contentful-service";
import DynamicPage from "../components/dynamic-page";
import DivisionHomepageLayout from "../components/layouts/division-homepage-layout";


const fetchDynamicRoutes = async (divisionCode) => {
    try {
        // Fetch the routes based on serviceId or other parameters
        const result = await getDataFromCmsApi(`divisionService/${divisionCode}`);

        const mappedRoutes = result.map((route) => ({
            path: route.link || '*',
            component: <DivisionHomepageLayout division={{ tag: 'revenueRecovery', title: 'Revenue Recovery Division', divisionAbbreviation: 'RVR' }} > <DynamicPage webpageId={route.webpage?.pageId} parentServiceId={route.id} /></DivisionHomepageLayout >,
            meta: { title: route.title, description: route.description }
        }));

        return mappedRoutes;
    } catch (error) {
        console.error("Error fetching routes", error);
    }

};

export default fetchDynamicRoutes;
