import React from "react";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { useParallax } from "react-scroll-parallax";
import ImportantLinksActionCard from "../action-card/link-action-card";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import DivisionServiceSearchAutocomplete from '../search/division-service-search-autocomplete.js'
import { getColorByIndex } from "../../utils/utils.js";


const fadeDuration = 1500;


const ImportantLinks = () => {
  const header = useParallax({ scale: [1.15, 1.25, "easeInQuad"] });
  //const search = useParallax({ scale: [1, 1.15, "easeInQuad"] });
  const [importantLinks, setImportantLinks] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("divisionService/GetHomePageEntries");
      setImportantLinks(result);
  };

  //useEffect(() => {
  //  console.log(importantLinks); // Verify state
  //}, [importantLinks]);
  const directions = ['left', 'down', 'right'];
  return (
    <div className="important-links">
      <div className="display-6 header py-2 text-center" ref={header.ref}>
        How may we serve you?
      </div>
      {/*<div className="d-flex justify-content-center pt-2 pb-5" ref={search.ref}>*/}
      {/*    <Search inputSize="lg" iconSize="1x" />*/}
      {/*</div>*/}
      <div className="d-flex justify-content-center py-4 pb-5">
        <DivisionServiceSearchAutocomplete />
      </div>
      <div className="container grid-container grid-style-3 py-4">
        {importantLinks && importantLinks.map((item, i) => (
          <div key={i}>
            <Fade className="h-100" triggerOnce duration={fadeDuration} direction={directions[i % directions.length]}>
              <ImportantLinksActionCard
                title={item.serviceLabel}
                linkDestination={item.link}
                cardColor={getColorByIndex(i)}
              >
                {parseHtml(item.shortDescription)}
              </ImportantLinksActionCard>
            </Fade>
          </div>
        ))}
      </div>
    </div>
  );

};

export default ImportantLinks;
