import React from "react";
import { Grid, Typography } from "@mui/material";
import AwardCard from "../../components/awards/award-card";
import PropType from "prop-types";
import { parseHtml } from "../../utils/utils";

AwardsBanner.propTypes = {
  awards: PropType.string.isRequired,
  awardsAwards: PropType.string.isRequired,
};

function AwardsBanner({ awards, awardsAwards }) {
  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100vw",
          height: "auto",
          padding: 3,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: "scaleX(-1)",
            backgroundColor: "#003366",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1200 800'%3E%3Cdefs%3E%3CradialGradient id='a' cx='0' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232e5881'/%3E%3Cstop offset='1' stop-color='%232e5881' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='b' cx='1200' cy='800' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%238099b3'/%3E%3Cstop offset='1' stop-color='%238099b3' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='c' cx='600' cy='0' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23aebece'/%3E%3Cstop offset='1' stop-color='%23aebece' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='d' cx='600' cy='800' r='600' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23003366'/%3E%3Cstop offset='1' stop-color='%23003366' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='e' cx='0' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%235C7C9C'/%3E%3Cstop offset='1' stop-color='%235C7C9C' stop-opacity='0'/%3E%3C/radialGradient%3E%3CradialGradient id='f' cx='1200' cy='0' r='800' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23FFFFFF'/%3E%3Cstop offset='1' stop-color='%23FFFFFF' stop-opacity='0'/%3E%3C/radialGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='1200' height='800'/%3E%3Crect fill='url(%23b)' width='1200' height='800'/%3E%3Crect fill='url(%23c)' width='1200' height='800'/%3E%3Crect fill='url(%23d)' width='1200' height='800'/%3E%3Crect fill='url(%23e)' width='1200' height='800'/%3E%3Crect fill='url(%23f)' width='1200' height='800'/%3E%3C/svg%3E")`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            zIndex: -99,
          }}
        ></div>
        <div className="container mt-5 pb-5">
          <Typography sx={{ marginBottom: 5, fontSize: 30 }}>Awards</Typography>
          <Grid container justifyContent="space-between" className="mb-5">
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              paddingRight={10}
              className="mb-3"
            >
              <div className="mb-4">
                <img
                  src={awards.contentImageUrl}
                  alt="NACo"
                  style={{
                    width: "50%",
                    height: "50%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div>{parseHtml(awards.content)}</div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              <Grid
                container
                spacing={2}
                justifyContent="left"
                alignItems="start"
              >
                {awardsAwards.map((award, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={index}>
                    <AwardCard
                      icon={award.imageUrl}
                      title={award.title}
                      content={parseHtml(award.description)}
                      secondaryIcon={award.secondaryImageUrl}
                      secondaryContent={award.secondaryDescription}
                      link={award.link}
                      linkText={award.linkText}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default AwardsBanner;
