import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getDataFromCmsApi } from '../api/contentful-service';
import StaticPageContainer from './static-page';
import { parseHtml } from '../utils/utils';
import SubServicesContainer from './sub-services/sub-services-container';
import Loader from './loader';

const DynamicPage = ({ webpageId, parentServiceId }) => {
    const [content, setContent] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch content based on webpageId passed as a prop
                const response = await getDataFromCmsApi(`webpage/${webpageId}`);

                if (response) {
                    console.log("Fetched content:", response);
                    setContent(response);
                } else {
                    setError("Content not found");
                }
            } catch (err) {
                console.error("Error fetching content:", err);
                setError("An error occurred while fetching data.");
            }
        };

        fetchData();
    }, [webpageId, parentServiceId]);

    if (error) {
        return <div>{error}</div>;
    }

    if (!content) {
        return <Loader />
    }

    return (
        <div>
            <StaticPageContainer title={content.pageTitle}>
                {parseHtml(content.content)}
                {parentServiceId && <SubServicesContainer parentServiceId={parentServiceId} />}
            </StaticPageContainer>
        </div>
    );
};

DynamicPage.propTypes = {
    webpageId: PropTypes.string,
    parentServiceId: PropTypes.string,
}

export default DynamicPage